import qs from 'qs'

const fields = [
  'long', 'lat'
]

export const queryEventLocationMapThumbnails = (options: QueryManyOptions) => {
  const sortKey = strapiGetI18nSortKey(options?.locale, 'titles.i18n_string')
  const filters = options?.filters ? options.filters : {}

  const query = qs.stringify(
    {
      publicationState: options?.publicationState || 'live',
      sort: [`${sortKey}:asc`],
      pagination: {
        limit: -1
      },
      filters: {
        $and: [
          {
            long: { $notNull: true }
          },
          {
            lat: { $notNull: true }
          },
          {
            event_entries: filters
          }
        ],
      },
      fields,
      populate: {
        ...queryFragmentCollectionTypeDefault,
        event_entries: {
          populate: {
            ...queryFragmentCollectionTypeDefault,
            cover_image: true,
            address: true,
            accessibility_types: {
              populate: queryFragmentCollectionTypeDefault
            },
            opening_times: true,
          }
        },
        cluster_entry: {
          fields: ['background_color']
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `event-locations?${query}`
}
